import { AssetValuation as AssetValuationDS, EquityListItem } from '@trustwise/design-system';
import { Model } from 'core/model';
import { Portfolio } from 'investments/interfaces';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { TokenContractSubType } from 'investments/types';
import { TokenContractSubTypeChoices } from 'investments/consts';

interface AssetValuation extends Omit<AssetValuationDS, 'pricePerUnit' | 'quotation'> {
  /**
   * Equity currency
   */
  currency: string;
  /**
   * Price per unit
   */
  pricePerUnit: string;
}

export interface EquityItemModelProps {
  /**
   * Equity item ID
   */
  id: number;
  /**
   * Equity item name
   */
  name: string;
  /**
   * Token contract type
   */
  contractType: string;
  /**
   * Equity issuer
   */
  legalEntity: SimpleLegalEntityModel;
  /**
   * Token contract subtype
   */
  tokenSubType: TokenContractSubType;
  /**
   * Portfolio on which the equity is allocated
   */
  portfolio: Portfolio;
  /**
   * Number of units owned by active entity on the given portfolio
   */
  amount: number;
  /**
   * Equity valuation
   */
  valuation: AssetValuation;
  /**
   * Equity price per unit (in base currency of the legal entity)
   */
  pricePerUnitBase: string;
  /**
   * Indicates whether equity represents equity
   */
  representsEquity: boolean;
}

export interface IEquityItemModelProps extends EquityListItem {
  /**
   * Token contract subtype
   */
  tokenSubType: TokenContractSubType;
}

export class EquityItemModel extends Model implements IEquityItemModelProps {
  id: number;
  name: string;
  legalEntity: SimpleLegalEntityModel;
  tokenSubType: TokenContractSubType;
  portfolio: Portfolio;
  amount: number;
  valuation: AssetValuationDS;
  pricePerUnitBase: number;
  hasVotingRights: boolean;
  isRestricted: boolean;

  constructor({
    legalEntity, pricePerUnitBase, valuation, representsEquity, tokenSubType, ...restProps
  }: EquityItemModelProps) {
    super(restProps);
    this.legalEntity = SimpleLegalEntityModel.fromResponse(legalEntity);
    this.pricePerUnitBase = Number(pricePerUnitBase);
    this.valuation = {
      ...valuation,
      quotation: valuation.currency,
      pricePerUnit: Number(valuation.pricePerUnit),
    };
    this.hasVotingRights = representsEquity;
    this.isRestricted = tokenSubType === TokenContractSubTypeChoices.RESTRICTED;
  }
}
