import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { EquitiesTable } from '@trustwise/design-system';
import { getApiUrl, getUrl } from 'utils/urls';
import { getLinkInteraction } from 'core/utils';
import { FullPageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import axios from 'core/axios';
import InvestmentsDateFilterForm from './InvestmentsDateFilterForm';
import AssetsAllocationChart from './parts/chart';
import AssetsValue from './parts/assetsValue';
import { EquityItemModel } from './models/equity';

/**
  * Equity Investments list
  *
  * @todo switch to using reducer for portfolios
  * @todo move chart into a separate file
  * @todo remove nested ternary
*/
const EquityInvestmentsList = ({ baseCurrency }) => {
  const [assets, setAssets] = useState({});
  const [totalAssetsValue, setTotalAssetsValue] = useState(undefined);
  const [editDate, setEditDate] = useState(false);

  const urlSearch = new URL(window.location.href).search;

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/', urlSearch))
      .then(({ data }) => { setAssets(EquityItemModel.fromResponse(data)); })
      .catch(console.error);
  }, [urlSearch]);

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/total-assets-value/', urlSearch))
      .then(({ data }) => { setTotalAssetsValue(data); })
      .catch(console.error);
  }, [urlSearch]);

  return (
    <FullPageContent
      pageHeading={gettext('Equity Investments')}
      headerLeft={<BackButton href={getUrl()} />}
      pageMessages={<li className="auto-hide success hidden" key="copied-message">{gettext('Copied')}</li>}
      actions={[
        {
          url: 'ownership/',
          icon: 'building',
          label: gettext('By company'),
          useRouterLink: false,
        },
        {
          type: 'button',
          onClick: () => { setEditDate(!editDate); },
          icon: 'calendar-day',
          label: gettext('As per date'),
        },
      ]}
    >
      <>
        <div style={{ marginBottom: '40px' }}>
          <InvestmentsDateFilterForm edit={editDate} />
          <AssetsValue totalAssetsValue={totalAssetsValue} currency={baseCurrency} />
          <AssetsAllocationChart baseCurrency={baseCurrency} urlSearch={urlSearch} />
        </div>
        <EquitiesTable
          equities={assets}
          baseCurrency={baseCurrency}
          onEquityClick={(id, portfolioId) => getLinkInteraction(`/equity/${portfolioId}/${id}/`)}
        />
      </>
    </FullPageContent>
  );
};

EquityInvestmentsList.defaultProps = {
  baseCurrency: 'CHF',
};

EquityInvestmentsList.propTypes = {
  baseCurrency: PropTypes.string,
};

export default EquityInvestmentsList;
