import { FormikHelpers } from 'formik';

import { DataSetVerificationStatuses } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { DataEntryItemModel } from 'dataRooms/models';
import { signFormTransaction } from 'utils/signTransaction';
import { VerdictValues } from 'dataRooms/types';
import { convertKeysToSnakeCase } from 'core/utils';
import { addMultipleDocuments, onFileDownload } from 'media/utils';
import { QueryKeyProp } from 'core/types';
import { onEntryFileDownload, onSchemaFilesOpen } from 'dataRooms/views/actions';
import { SimpleDocumentModel } from 'media/models';
import { PendingVerificationsModel, ReviewedDataSetModel, DataSetDetailsModel, GrantTokenModel } from './models';

const verificationPath = '/services/data-verification/data-sets/';
const getDetailsPath = (dataSetId: string) => `${verificationPath}${dataSetId}/`;

export const getVerifications = (isPending: boolean): Promise<PendingVerificationsModel[] | ReviewedDataSetModel[]> => {
  const urlParams = new URLSearchParams();
  if (isPending) {
    urlParams.append('verification_statuses', DataSetVerificationStatuses.PENDING);
  } else {
    urlParams.append('verification_statuses', DataSetVerificationStatuses.VERIFIED);
    urlParams.append('verification_statuses', DataSetVerificationStatuses.REJECTED);
  }
  return (
    axios
      .get(getApiUrl(verificationPath, urlParams))
      .then(({ data }) => {
        if (isPending) {
          return PendingVerificationsModel.fromResponse(data);
        }
        return ReviewedDataSetModel.fromResponse(data);
      })
  );
};

export const getDataSet = ({ queryKey: [, dataSetId] }: QueryKeyProp): Promise<DataSetDetailsModel> => (
  axios.get(getApiUrl(getDetailsPath(dataSetId)))
    .then(({ data }) => DataSetDetailsModel.fromResponse(data))
);

export const getDataEntries = ({ queryKey: [, dataSetId] }: QueryKeyProp): Promise<DataEntryItemModel[]> => (
  axios.get(getApiUrl(`${getDetailsPath(dataSetId)}entries/`))
    .then(({ data: { results } }) => DataEntryItemModel.fromResponse(results))
);

export const submitVerdict = (
  values: VerdictValues,
  actions: FormikHelpers<VerdictValues>,
  dataSetId: string,
) => {
  const { documents, ...restProps } = values;
  const data = convertKeysToSnakeCase(restProps);
  const submitData = new FormData();
  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value as string),
  );
  addMultipleDocuments({ documents, submitData, fieldName: 'verifier_reports' });

  const detailsPath = getDetailsPath(dataSetId);

  return signFormTransaction({
    urlPath: `${detailsPath}submit/`,
    navigatePath: detailsPath,
    data: submitData,
    actions,
  });
};

export const onDataEntryFileDownload = (
  dataEntryId: number,
  fileId: number,
  dataSetId: string,
  dataEntries?: DataEntryItemModel[],
) => onEntryFileDownload(dataEntryId, fileId, getDetailsPath(dataSetId), dataEntries);

export const onReportDownload = (
  fileId: number,
  dataSetId: string,
  reports: SimpleDocumentModel[],
) => onFileDownload(fileId, reports, getDetailsPath(dataSetId));

export const getGrantTokens = ({ queryKey: [, dataSetId] }: QueryKeyProp): Promise<GrantTokenModel[]> => (
  axios.get(getApiUrl(`${getDetailsPath(dataSetId)}grant-tokens/`))
    .then(({ data }) => GrantTokenModel.fromResponse(data))
);

export const onSchemaEntryFilesOpen = (dataEntryId: number, fieldKey: string, dataSetId: string) => (
  onSchemaFilesOpen(dataEntryId, fieldKey, getDetailsPath(dataSetId))
);
